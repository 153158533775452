<template>
  <div class="contain" v-title data-title="商品详情" v-if="proData">
    <div class="topswipe">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in proData.imageList" :key="index">
          <van-image width="100%" height="100%" :src="img_url + item.imgUrl" @click="onLargeImg(item.imgUrl)"/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <template v-if="jsonData">
      <div class="ta">
        <div class="ta-2">[{{proData.proBrandName}}]{{proData.proName}}</div>
        <div class="ta-3" v-if="proData.proAlias">[{{proData.proAlias}}]</div>
        <div class="ta-1">
          <span class="coe4 bold">
            <span>￥</span><span class="fz20">{{jsonData.showBackPrice}}</span>
          </span>
        </div>
      </div>
      <div class="tb">
        <div class="tb-1">查看规格/单位</div>
        <div class="tb-2">
          <div class="item2s active">
            <div class="flex" style="justify-content: center;align-items: center;margin-right: 3px;">
              <van-image width="40px" height="40px" v-if="jsonData.specImage" :src="img_url + jsonData.specImage"/>
              <van-image width="40px" height="40px" v-else :src="img_url + defalutImg" />
            </div>
            <div>{{jsonData.specName}}</div>
          </div>
        </div>
<!--        <div class="tb-3">-->
<!--          <div class="item3 active">-->
<!--            {{jsonData.unitName}}-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </template>
    <template v-else>
      <div class="ta">
        <div class="ta-2">[{{proData.proBrandName}}]{{proData.proName}}</div>
        <div class="ta-3" v-if="proData.proAlias">[{{proData.proAlias}}]</div>
        <div class="ta-1" v-if="proData.productDetailSpecifications.length">
          <span class="coe4 bold">
            <span>￥</span><span class="fz20">{{proData.productDetailSpecifications[idx].proDetailUnits[idx2].onlinePrice}}</span>
          </span>
          <span class="cob3" style="text-decoration: line-through;">￥{{proData.productDetailSpecifications[idx].proDetailUnits[idx2].marketPrice}}</span>
        </div>
      </div>
      <div class="tb" v-if="proData.productDetailSpecifications.length">
        <div class="tb-1">选择规格/单位</div>
        <div class="tb-2">
          <div class="item2" :class="idx === index? 'active' : '' " v-for="(item, index) in proData.productDetailSpecifications"
               :key="index" @click="selectSpec(index)">
            <div class="flex" style="justify-content: center;align-items: center;margin-right: 3px;">
              <van-image width="40px" height="40px" v-if="item.specificationImage" :src="img_url + item.specificationImage"/>
              <van-image width="40px" height="40px" v-else :src="img_url + defalutImg" />
            </div>
            <div>{{item.specification}}</div>
          </div>
        </div>
        <div class="tb-3">
          <div class="item3" :class="idx2 === index2? 'active' : '' " v-for="(item2, index2) in proData.productDetailSpecifications[idx].proDetailUnits"
               :key="index2" @click="selectUnit(index2)">
            {{item2.unitName}}
          </div>
        </div>
      </div>
    </template>
    <div class="tc" v-if="proData.productDetailSpecifications.length && proData.productDetailSpecifications[idx].proDetailUnits[idx2].rules">
      <div class="tc-1 bold" style="width: 10%">
        <span>优惠</span>
      </div>
      <div class="tc-1">
        <div class="tci" v-for="(item, index) in proData.productDetailSpecifications[idx].proDetailUnits[idx2].rules" :key="index">
          <span class="item">{{item}}</span>
        </div>
      </div>
    </div>
    <div class="td">
      <div class="td-1">商品详情</div>
      <div class="td-2">
        <div class="td2-item">
          <div class="iteml">厂家：</div>
          <div class="itemr">{{proData.proManufacturersName}}</div>
        </div>
        <div class="td2-item">
          <div class="iteml">品牌：</div>
          <div class="itemr">{{proData.proBrandName}}</div>
        </div>
        <div class="td2-item" v-for="(item, index) in proData.proAttributeList" :key="index">
          <div class="iteml">{{item.attributeName}}：</div>
          <div class="itemr">{{item.attributeValue}}</div>
        </div>
      </div>
    </div>
    <div class="te" v-html="proData.proContext"></div>
    <div class="bottom" v-if="proStatus !== 'Y' && proStatus !== 'N'">
      <van-button class="bottom-item" type="default" size="small" v-if="proData.proStatus === 3" @click="edit">编辑</van-button>
      <van-button class="bottom-item" type="info" size="small" @click="delet">删除</van-button>
    </div>
    <van-popup v-model="largeImg">
      <div style="width: 100vw">
        <van-image width="100%" height="100%" :src="img_url + largeImgUrl"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { versions } from '@/common/util'
import {
  productShopDetail
} from '@/common/api'

export default {
  name: 'goodsDetail',
  data () {
    return {
      // 请求商品详情
      proId: '',
      shopId: '',
      sessionID: '',
      gradeId: '',
      inputNum: 1,
      popStatus: false,
      proData: '',
      proStatus: '',
      jsonData: null,
      // 外面商品信息
      idx: 0,
      idx2: 0,
      img_url: process.env.VUE_APP_IMG_URL,
      largeImg: false,
      largeImgUrl: '',
      defalutImg: ''
    }
  },
  mounted () {
    // console.log('h5接收', this.$route.query)
    window.getFromAndroid = this.getFromAndroid
    window.getFromiOS = this.getFromiOS
    const ver = versions()
    if (ver.wxMiniProgram) {
      console.log('小程序')
    } else if (ver.ios) {
      console.log('ios')
    } else {
      console.log('安卓')
    }
    // const res = JSON.stringify({ gradeId: '133000444975783936', jsonData: { showBackPrice: 15.0, specName: '233 千克', unitName: '千克', specImage: 'FumQwUhoyYn8zZbcThTgQDb3xB4K' }, proStatus: 'N', proId: '132992146381742080', sessionID: 'UserSession_50B1C179CCAA271D3776B0D1977892ED', shopId: '133000444615073792' })
    // console.log(res)
    // const resdata = JSON.parse(res)
    // console.log(resdata)
    // this.jsonData = resdata.jsonData
    // this.proId = resdata.proId
    // this.sessionID = resdata.sessionID
    // this.proStatus = resdata.proStatus
    // this.shopId = resdata.shopId
    // this.gradeId = resdata.gradeId
    // localStorage.setItem('sessionID', 'UserSession_50B1C179CCAA271D3776B0D1977892ED')
    // this.getDetail()
  },
  methods: {
    getDetail () {
      const tmpData = {
        proId: this.proId,
        shopId: this.shopId,
        proStatus: this.proStatus,
        gradeId: this.gradeId
      }
      this.$loading.show()
      productShopDetail(tmpData).then(res => {
        this.$loading.hide()
        this.proData = res.resultObject
        this.defalutImg = res.resultObject.imageList[0].imgUrl
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },
    showPop () {
      this.popStatus = true
    },
    closePop () {
      this.popStatus = false
    },
    addCart () {
      this.$toast('无此功能')
    },
    selectSpec (index) {
      this.idx = index
      this.idx2 = 0
    },
    selectUnit (index2) {
      this.idx2 = index2
    },
    // addNum () {
    //   let num = this.inputNum
    //   num++
    //   this.inputNum = num
    // },
    // reduceNum () {
    //   let num = this.inputNum
    //   num--
    //   if (num < 1) {
    //     this.$toast('数量不能小于1')
    //     this.inputNum = 1
    //   } else {
    //     this.inputNum = num
    //   }
    // },
    // onInputNum () {
    //   const num = this.inputNum
    //   if (num < 1) {
    //     this.$toast('数量不能小于1')
    //     this.inputNum = 1
    //   }
    // },
    edit () {
      const ver = versions()
      if (ver.wxMiniProgram) {
        console.log('小程序')
      } else if (ver.ios) {
        window.webkit.messageHandlers.presentEdit_iOS.postMessage(this.proData.proId)
      } else {
        // eslint-disable-next-line no-undef
        submitAndroid.presentEdit_Android(this.proData.proId)
      }
    },
    delet () {
      const ver = versions()
      if (ver.wxMiniProgram) {
        console.log('小程序')
      } else if (ver.ios) {
        window.webkit.messageHandlers.presentDelete_iOS.postMessage(this.proData.proId)
      } else {
        // eslint-disable-next-line no-undef
        submitAndroid.presentDelete_Android(this.proData.proId)
      }
    },
    // 接收安卓和IOS
    getFromAndroid (res) {
      const resdata = JSON.parse(res)
      this.proId = resdata.proId
      this.sessionID = resdata.sessionID
      this.proStatus = resdata.proStatus
      this.shopId = resdata.shopId
      this.gradeId = resdata.gradeId
      this.jsonData = resdata.jsonData
      localStorage.setItem('sessionID', this.sessionID)
      this.getDetail()
    },
    getFromiOS (res) {
      const resdata = JSON.parse(res)
      this.proId = resdata.proId
      this.sessionID = resdata.sessionID
      this.proStatus = resdata.proStatus
      this.shopId = resdata.shopId
      this.gradeId = resdata.gradeId
      this.jsonData = resdata.jsonData
      localStorage.setItem('sessionID', this.sessionID)
      this.getDetail()
    },
    onLargeImg (e) {
      this.largeImg = true
      this.largeImgUrl = e
    }
  }
}
</script>

<style scoped lang="scss">
  .contain {
    background-color: #F3F3F3;
    padding-bottom: 50px;
  }
  .topnav {
    ::v-deep .van-nav-bar__left .van-icon{
      color: #000;
    }
    ::v-deep .van-nav-bar__right .van-icon{
      color: #000;
    }
  }
  .topswipe {
    .van-swipe {
      height: 375px;
    }
  }
  .ta {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    .ta-1 {
      font-size: 11px;
    }
    .ta-2 {
      font-size: 16px;
      font-weight: bold;
    }
    .ta-3 {
      font-size: 12px;
      color: #D7A963;
      margin-top: 3px;
    }
  }
  .tb {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    .tb-1 {
      font-size: 12px;
      font-weight: bold;
    }
    .tb-2 {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      .item2 {
        width: 110px;
        font-size: 12px;
        border: 1px solid #F3F3F3;
        border-radius: 3px;
        background-color: #F3F3F3;
        display: flex;
        margin: 10px 10px 0 0;
        padding: 3px;
        cursor: pointer;
        align-items: center;
      }
      .item2s {
        width: 160px;
        font-size: 12px;
        border: 1px solid #F3F3F3;
        border-radius: 3px;
        background-color: #F3F3F3;
        display: flex;
        margin: 10px 10px 0 0;
        padding: 3px;
        cursor: pointer;
        align-items: center;
      }
      .item2:nth-child(3n) {
        margin-right: 0;
      }
      .active {
        background-color: #fff;
        border: 1px solid #E40404;
        border-radius: 3px;
        color: #E40404;
      }
    }
    .tb-3 {
      display: flex;
      flex-wrap: wrap;
      .item3 {
        font-size: 12px;
        border: 1px solid #F3F3F3;
        border-radius: 3px;
        background-color: #F3F3F3;
        margin: 10px 10px 0 0;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
      }
      .active {
        background-color: #fff;
        border: 1px solid #E40404;
        border-radius: 3px;
        color: #E40404;
      }
    }
  }
  .tc {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    .tc-1 {
      .tci:not(:first-child) {
        margin-top: 10px;
      }
      .item {
        color: #E40404;
        padding: 3px 5px;
        border: 1px solid #E40404;
        border-radius: 3px;
      }
    }
  }
  .td {
    margin-top: 10px;
    background-color: #fff;
    padding: 10px;
    .td-1 {
      font-size: 14px;
      font-weight: bold;
    }
    .td-2 {
      margin-top: 10px;
      .td2-item {
        display: flex;
        background-color: #F5F5F5;
        font-size: 12px;
        .iteml {
          flex: 0 0 30%;
          padding: 10px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
        .itemr {
          flex: 1;
          padding: 10px;
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
  .te {
    background-color: #fff;
    width: 100vw;
  }
  .hot {
    margin-top: 10px;
    background-color: #fff;
    .ha {
      text-align: center;
      padding: 10px 0px;
    }
    .hb {
      .flexGoods {
        display: flex;
        flex-wrap: wrap;
        padding: 0 8px;
      }
    }
  }
  .bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    height: 48px;
    line-height: 48px;
    text-align: right;
    .bottom-item {
      width: 85px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .br {
      flex: 1;
      background-color: #E40404;
      line-height: 48px;
      font-size: 16px;
    }
  }
  .popup {
    .goodsitem-contain {
      padding: 10px;
      font-size: 13px;
    }
    .goodsitem-contain .a {
      display: flex;
      margin-top: 15px;
      border-bottom: 1px solid #f3f3f3;
      padding-bottom:10px;
    }
    .goodsitem-contain .a .l {
      width: 30%;
    }
    .goodsitem-contain .a .r {
      width: 68%;
    }
    .goodsitem-contain .b {
      padding: 10px 0;
    }
    .goodsitem-contain .b .bs {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .goodsitem-contain .b .tag {
      background-color: #F5F5F5;
      border-radius: 3px;
      padding: 9px 10px;
      margin-right: 10px;
      border: 1px solid #F5F5F5;
    }
    .goodsitem-contain .b .selecttag {
      background-color: #fff;
      color: #E40404;
      border: 1px solid #E40404;
      border-radius: 3px;
    }
    .goodsitem-contain .b .bd {
      margin-top: 20px;
    }
    .goodsitem-contain .b .btn {
      color: #fff;
      background-color: #E40404;
      border-radius: 8px;
    }
  }
  .visibility {
    visibility: hidden;
  }
</style>
