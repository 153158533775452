// 判断环境
export function versions () {
  var ua = window.navigator.userAgent
  return {
    trident: ua.indexOf('Trident') > -1, // IE内核
    webKit: ua.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!ua.match(/AppleWebKit.*Mobile.*/) || !!ua.match(/AppleWebKit/), // 是否为移动终端
    android: ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1, // android终端或者uc浏览器
    ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    iPhone: ua.indexOf('iPhone') > -1 || ua.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: ua.indexOf('iPad') > -1, // 是否iPad
    wxMiniProgram: ua.indexOf('MicroMessenger') > -1,
    ToutiaoMicroApp: ua.indexOf('ToutiaoMicroApp') > -1 || ua.indexOf('bytedanceide') > -1,
    Alipay: ua.indexOf('AlipayClient') > -1
  }
}
// 优惠类型
export function switchYouHui (data) {
  let youhuiName = ''
  switch (data) {
    case 0:
    case null:
      youhuiName = ''
      break
    case 10:
      youhuiName = '满送'
      break
    case 12:
      youhuiName = '阶梯送'
      break
    case 20:
      youhuiName = '满赠'
      break
    case 22:
      youhuiName = '阶梯赠'
      break
    case 30:
      youhuiName = '满减'
      break
    case 32:
      youhuiName = '阶梯减'
      break
    case 40:
    case 42:
      youhuiName = '折扣'
      break
    case 50:
      youhuiName = '特价'
      break
  }
  return youhuiName
}
// 相加精度
export function accAdd (arg1, arg2) {
  var r1, r2, m
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}
// 相减精度
export function subtr (arg1, arg2) {
  var r1, r2, m, n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}
// 四舍五入精度
export function fixTo (v, e) {
  var t = 1
  for (;e > 0; t *= 10, e--);
  for (;e < 0; t /= 10, e++);
  return Math.round(v * t) / t
}
// 生成随机字符
export function randomString () {
  function randomString (length, chars) {
    var result = ''
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
    return result
  }
  var rString = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
  return rString
}
